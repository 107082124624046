/*!
* WS Starter
* Designed by LRS Web Solutions (http://lrswebsolutions.com)
* Visit http://lrswebsolutions.com
*/

// Presets & Variables
@import 'presets/variables';
@import 'presets/mixins';
// Additional Presets & Variables
@import 'presets/grid-settings';
@import 'presets/helper-classes';
// Layout
@import 'layout/sidebar';
// Vendor
@import 'vendor/magnific-popup';
@import 'vendor/accordions';
@import 'vendor/tabs';
@import 'vendor/tables';
@import 'vendor/responsive-videos';
// Components
@import 'components/images';
@import 'components/accordions';
@import 'components/photo-gallery';
@import 'components/inventory';
@import 'components/inventory-details';
@import 'components/team';
