.team {
  display: inline-flex;
  max-width: 1200px;
  width: 100%;

  flex-wrap: wrap;

  .team-member {
    margin: 0 20px 20px 0;

    .name {
      font-weight: 700;

      a {
        padding-right: 10px;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      &:after {
        color: black;
        content: '\f0e0';
        font-weight: 100;
        font-family: FontAwesome;
      }
    }

    .title {
      font-style: italic;
      font-size: 14px;
    }
  }
}
