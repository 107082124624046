.gallery-container {
    position: relative;
    overflow: hidden;
    margin-top: -50px;
    margin-right: 0;
    margin-left: -20px;
    width: calc(100% + 40px);
    height: auto;

    @media screen and (min-width: $medium) {
        margin-top: -45px;
        margin-left: -20px;
        height: 450px;
    }

    @media screen and (min-width: $large) {
        margin-top: -45px;
        margin-left: -50px;
        width: calc(100% + 100px);
        height: 450px;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ Specific CSS styles */
        margin-top: 0;

        img {
            max-width: inherit !important;
            width: inherit !important;
        }
    }
}

.gallery-nav {
    position: absolute;
    top: 50%;
    z-index: 25;
    display: none;
    width: 50px;
    height: 100px;
    border: 1px solid #fff;
    background: #000;
    color: #fff;
    text-align: center;
    font-size: 3rem;
    opacity: 0.6;
    cursor: pointer;
    transform: translateY(-50%);

    align-items: center;
    justify-content: center;

    &.active {
        display: none;

        @media screen and (min-width: $medium) {
            display: flex;
        }
    }

    &:hover,
    &:focus {
        opacity: 1;
    }

    &.previous {
        left: 20px;
        border-left: 0;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            left: 15px;
        }
    }

    &.next {
        right: 20px;
        border-right: 0;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            right: 15px;
        }
    }

    &.restart {
        right: 20px;
        border-right: 0;
        font-size: 2rem;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            right: 15px;
        }
    }

    &.count {
        @include fontsize(12 24 1);

        position: absolute;
        top: 20px;
        right: 30px;
        display: block;
        padding: 0;
        height: auto;
        border: 0;
        background: rgba(0, 0, 0, 0.75);
        color: #fff;

        @media screen and (min-width: $medium) {
            display: none;
        }
    }
}

.photo-gallery {
    position: relative;
    left: 0;
    display: inline-flex;
    height: 100%;
    transition: left 0.5s ease-in-out;

    align-items: center;

    @media screen and (min-width: $medium) {
        display: flex;
        flex-direction: column;

        flex-wrap: wrap;
    }

    img,
    .image-preview {
        position: relative;
        width: 100vw;

        &:first-child {
            display: block;
        }

        @media screen and (min-width: $medium) {
            display: inline-block;
            margin-right: 5px;
            margin-bottom: 5px;
            width: 291px;
            height: calc(50% - 5px);

            &:first-child {
                width: auto;
                height: calc(100% - 5px);
            }
        }
    }
}

.image-preview a[data-mfp-src*='sddefault.jpg'] {
    position: relative;

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        display: block;
        width: 286px;
        height: 215px;
        background: url('/themes/isringhausen/assets/dist/images/ICO-play.png') no-repeat;
        background-size: cover;
        // content: '';
    }
}

a.popup-youtube {
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        display: block;
        width: 100%;
        height: 100%;
        background: url('/themes/isringhausen/assets/dist/images/ICO-play.png') no-repeat;
        background-size: cover;
        content: '';

        @media screen and (min-width: $medium) {
            width: 286px;
            height: 215px;
        }
    }
}
