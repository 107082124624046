.subpage .main-content {

  .spin-details-container {
    max-width: 640px;
  }

  .vehicle-details {
    position: relative;

    .page-actions {
      top: 0;
      right: 0;
      margin: 10px 0 0 0;
      color: #737373;
      text-align: left;

      @include fontsize(24 42 50);

      @media screen and (min-width: $large) {
        position: absolute;
        margin: 0;
      }

      a {
        color: #737373;
        text-decoration: none;
      }

      .fa {
        margin-right: 15px;
        cursor: pointer;

        &:hover {
          color: #333333;
        }
      }
    }

    h3.vehicle-title {
      margin: 40px 0 10px;
      color: #000000;
      text-transform: capitalize;
      font-weight: bold;

      @include fontsize(25 30 10);

      @media screen and (min-width: $large) {
        margin-top: 50px;
        padding-right: 20%;
      }
    }

    h4.vehicle-price {
      margin: 10px 0 25px;
      color: #000000;
      font-weight: bold;

      @include fontsize(24 30 1);

      @media screen and (min-width: $large) {
        min-height: 20px;
      }
    }

    .vehicle-specs {
      font-size: 1rem;

      .vehicle-actions {
        max-width: 350px;
        // max-width: 800px;
        // display: flex;
        // flex-wrap: wrap;

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 15px 15px 0;          
          // max-width: 750px;
          color: #ffffff;
          text-decoration: none;
          text-transform: none;
          font-size: 16px;

          span {
            display: inline-block;
            width: 25px;
            height: 25px;
            margin-right: 6px;
          }
        }
      }

      @media screen and (min-width: $large) {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
      }

      .more-info {
        position: relative;
        margin-bottom: 40px;
        padding: 40px 0;
        width: 100%;
        border-bottom: 1px solid #c6c6c6;

        @media screen and (min-width: $large) {
          display: inline-flex;
          margin: 0;
          padding: 0;
          width: auto;
          border: 0;
        }

        a,
        span {
          display: inline-block;
          // margin-bottom: 25px;
          // height: 0;

          @media screen and (min-width: $large) {
            position: relative;
            // top: 75%;
            // left: -25%;
            margin: 0 15px;
          }

          &:last-child {
            margin-bottom: 0;

            @media screen and (min-width: $large) {
              margin-bottom: 25px;
            }
          }

          img {
            vertical-align: middle;
          }
        }
      }

      >ul,
      >div {
        margin-right: 50px;

        &:last-child {
          margin-right: 0;

          flex-grow: 1;
        }
      }

      ul {
        margin: 0 50px 30px 0;
        padding: 0;

        @include fontsize(18 24 1);

        li {
          margin: 0;
          padding: 0;
          border: 0;

          &:before {
            content: none;
          }
        }
      }

      .btn {
        margin-bottom: 10px;
      }
    }
  }

  @media print {

    header,
    .page-actions,
    .vehicle-actions,
    .service-appointment,
    .testimonials.parallax-window,
    .standard-equipment,
    .more-info,
    footer {
      display: none;
    }

    @media screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
      .image-preview img {
        width: 100% !important;
      }
    }

    .image-preview img {
      width: 100%;
    }

    .image-preview~.image-preview {
      display: none;
    }

    .vehicle-details {
      padding-bottom: 20px;

      h4.vehicle-price {
        margin-bottom: 10px !important;
      }
    }

    .testimonials {
      background: none !important;
    }

    .accordions.acc-large {
      margin: 0 !important;
      border: none !important;
    }

    h3.accordion-title button {
      padding: 0 3.125rem !important;
    }

    .accordion-content[aria-hidden] {
      display: block !important;
    }

    .vehicle-standard-options.accordion-content {
      display: none !important;
    }

    .accordion-content {
      margin-bottom: 30px;
      padding: 0 3.125rem !important;

      p,
      ul,
      ol,
      li {
        padding: 0 !important;
        border: none !important;
        list-style: initial !important;
        font-size: 12px !important;
        line-height: 1.5 !important;
      }
    }
  }
}