/*!
* WS Starter
* Designed by LRS Web Solutions (http://lrswebsolutions.com)
* Visit http://lrswebsolutions.com
*/
@font-face {
  font-family: "Akzidenz";
  src: url("/themes/isringhausen/assets/dist/fonts/Berthold Akzidenz Grotesk Bold.otf");
}
.sr-only {
  position: absolute;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
  background: white;
  color: black;
}

.offscreen {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.hide {
  display: none;
}

.show {
  display: initial;
}

.invisible {
  visibility: hidden;
}

.clear {
  display: block;
  clear: both;
}

.clear-after:after {
  display: table;
  clear: both;
  content: "";
}

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.float-left {
  float: left;
  padding: 20px 55px 20px 0;
  margin: 0;
}

.float-right {
  float: right;
  padding: 20px 0px 20px 55px;
  margin: 0;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

@media (orientation: landscape) {
  .show-landscape {
    display: block;
  }
}
@media (orientation: portrait) {
  .show-landscape {
    display: none;
  }
}

@media (orientation: portrait) {
  .show-portrait {
    display: block;
  }
}
@media (orientation: landscape) {
  .show-portrait {
    display: none;
  }
}

@media (orientation: landscape) {
  .hide-landscape {
    display: none;
  }
}
@media (orientation: portrait) {
  .hide-landscape {
    display: block;
  }
}

@media (orientation: portrait) {
  .hide-portrait {
    display: none;
  }
}
@media (orientation: landscape) {
  .hide-portrait {
    display: block;
  }
}

@media (min-width: 640px) {
  .show-small-only {
    display: none;
  }
}

@media (min-width: 0) and (max-width: 639px) {
  .show-medium-only {
    display: none;
  }
}
@media (min-width: 1024px) {
  .show-medium-only {
    display: none;
  }
}

.show-large-only {
  display: none;
}
@media (min-width: 1024px) and (max-width: 1340px) {
  .show-large-only {
    display: block;
  }
}
@media (min-width: 1341px) {
  .show-large-only {
    display: none;
  }
}

.show-xlarge-only {
  display: none;
}
@media (min-width: 1341px) and (max-width: 1399px) {
  .show-xlarge-only {
    display: block;
  }
}
@media (min-width: 1400px) {
  .show-xlarge-only {
    display: none;
  }
}

.show-xxlarge-only {
  display: none;
}
@media (min-width: 1400px) {
  .show-xxlarge-only {
    display: none;
  }
}

.show-medium-up {
  display: none;
}
@media (min-width: 640px) {
  .show-medium-up {
    display: block;
  }
}

.show-large-up {
  display: none;
}
@media (min-width: 1024px) {
  .show-large-up {
    display: block;
  }
}

.show-xlarge-up {
  display: none;
}
@media (min-width: 1341px) {
  .show-xlarge-up {
    display: block;
  }
}

.show-xxlarge-up {
  display: none;
}
@media (min-width: 1400px) {
  .show-xxlarge-up {
    display: block;
  }
}

@media (min-width: 0) and (max-width: 639px) {
  .hide-small-only {
    display: none;
  }
}

@media (min-width: 640px) and (max-width: 1023px) {
  .hide-medium-only {
    display: none;
  }
}

@media (min-width: 1024px) and (max-width: 1340px) {
  .hide-large-only {
    display: none;
  }
}

@media (min-width: 1341px) and (max-width: 1399px) {
  .hide-xlarge-only {
    display: none;
  }
}

@media (min-width: 1400px) {
  .hide-xxlarge-only {
    display: none;
  }
}

@media (min-width: 640px) {
  .hide-medium-up {
    display: none;
  }
}

@media (min-width: 1024px) {
  .hide-large-up {
    display: none;
  }
}

@media (min-width: 1341px) {
  .hide-xlarge-up {
    display: none;
  }
}

@media (min-width: 1400px) {
  .hide-xxlarge-up {
    display: none;
  }
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #CCC;
}

.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }

  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }

  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }

  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }

  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }

  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }

  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }

  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }

  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }

  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }

  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}
.accordions {
  margin-bottom: 1.25rem;
}
.accordions h2.accordion-title,
.accordions h3.accordion-title {
  position: relative;
  margin: 0;
}
.accordions h2.accordion-title button,
.accordions h3.accordion-title button {
  position: relative;
  margin: 0;
  padding: 0.9375rem 0 0.625rem 0;
  width: 100%;
  border: 0;
  border-bottom: 1px solid #d3d3d3;
  background: transparent;
  color: #333333;
  text-align: left;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.05em;
  font-size: 1.125rem;
  line-height: 1.875rem;
}
.accordions h2.accordion-title button span,
.accordions h3.accordion-title button span {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 5rem;
  height: 100%;
  border: 0;
}
.accordions h2.accordion-title button span:after,
.accordions h3.accordion-title button span:after {
  position: absolute;
  top: 50%;
  right: 0;
  width: 15px;
  height: 15px;
  background: transparent url(../images/plus.png) no-repeat center center;
  background-size: contain;
  content: "";
  text-align: center;
  transform: translateY(-50%);
}
.accordions h2.accordion-title button:hover,
.accordions h3.accordion-title button:hover {
  cursor: pointer;
}
.accordions h2.accordion-title button:hover, .accordions h2.accordion-title button:focus,
.accordions h3.accordion-title button:hover,
.accordions h3.accordion-title button:focus {
  outline: 0;
  border: 0;
  border-bottom: 1px solid #c7352e;
  background: transparent;
  color: #c7352e;
}
.accordions h2.accordion-title button:hover span, .accordions h2.accordion-title button:focus span,
.accordions h3.accordion-title button:hover span,
.accordions h3.accordion-title button:focus span {
  border: 0;
}
.accordions h2.accordion-title button:hover span:after, .accordions h2.accordion-title button:focus span:after,
.accordions h3.accordion-title button:hover span:after,
.accordions h3.accordion-title button:focus span:after {
  color: #c7352e;
}
.accordions h2.accordion-title.is-active button, .accordions h2.accordion-title.is-active button:focus,
.accordions h3.accordion-title.is-active button,
.accordions h3.accordion-title.is-active button:focus {
  outline: 0;
  border: 0;
  border-bottom: 0;
  background: transparent;
  color: #333333;
}
.accordions h2.accordion-title.is-active button span, .accordions h2.accordion-title.is-active button:focus span,
.accordions h3.accordion-title.is-active button span,
.accordions h3.accordion-title.is-active button:focus span {
  border: 0;
}
.accordions h2.accordion-title.is-active button span:after, .accordions h2.accordion-title.is-active button:focus span:after,
.accordions h3.accordion-title.is-active button span:after,
.accordions h3.accordion-title.is-active button:focus span:after {
  background-image: url(../images/minus.png);
}
.accordions h2.accordion-title.is-active button a.clear-filters-btn, .accordions h2.accordion-title.is-active button:focus a.clear-filters-btn,
.accordions h3.accordion-title.is-active button a.clear-filters-btn,
.accordions h3.accordion-title.is-active button:focus a.clear-filters-btn {
  display: block;
}
.accordions h2.accordion-title a.clear-filters-btn,
.accordions h3.accordion-title a.clear-filters-btn {
  position: absolute;
  right: 20px;
  display: none;
  margin: 0;
  padding: 0;
  color: #c7352e;
  text-transform: uppercase;
  font-weight: medium;
  letter-spacing: 0.05em;
  font-size: 0.8125rem;
  line-height: 1.5625rem;
  font-family: "Roboto", sans-serif;
}
.accordions h2.accordion-title a.clear-filters-btn:before,
.accordions h3.accordion-title a.clear-filters-btn:before {
  content: none;
}
.accordions .accordion-content[aria-hidden] {
  display: none;
}
.accordions .accordion-content[aria-hidden=false] {
  display: block;
}
.accordions.active h2 {
  margin: 0;
}
.accordions.active .accordion-content {
  padding: 0 0 15px 0;
  border-bottom: 1px solid #d3d3d3;
  color: #333333;
  letter-spacing: 0.001em;
  font-size: 1.125rem;
  line-height: 1.875rem;
}
.accordions.active .accordion-content p:first-child {
  margin-top: 0;
}

.tabs {
  position: relative;
  margin-bottom: 1.25rem;
}
.tabs:not(.location-tabs).scroll-left:before, .tabs:not(.location-tabs).scroll-right:after {
  position: absolute;
  z-index: 99;
  width: 2.5rem;
  height: 3.75rem;
  content: "";
}
.tabs:not(.location-tabs).scroll-left:before {
  top: 0;
  left: 0;
  background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
}
.tabs:not(.location-tabs).scroll-right:after {
  top: 0;
  right: 0;
  background: linear-gradient(to left, white 0%, rgba(255, 255, 255, 0) 100%);
}
.tabs .tab-con {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 2px;
  padding: 0;
  white-space: nowrap;
}
.tabs ul.tab-list {
  letter-spacing: 0.001em;
  font-size: 1.125rem;
  line-height: 1.5rem;
  display: none;
  margin: 0;
  padding: 0;
  list-style: none;
  text-transform: capitalize;
  letter-spacing: 1px;
  font-weight: 900;
  font-family: "Roboto", sans-serif;
}
.tabs ul.tab-list[role=tablist] {
  display: inline-block;
}
.tabs ul.tab-list li {
  display: inline-block;
  margin: 0 0.625rem 0 0;
  padding: 0;
  border-bottom: 0;
  white-space: nowrap;
}
.tabs ul.tab-list li:last-child {
  margin: 0;
}
.tabs ul.tab-list li a {
  position: relative;
  display: block;
  padding: 0.75rem 1.1875rem;
  border-top: 5px solid #ccc;
  background: #fff;
  color: #333;
  text-align: center;
  text-decoration: none;
}
.tabs ul.tab-list li a:hover, .tabs ul.tab-list li a[role=tab]:focus {
  outline: 0;
  border-color: #c7352e;
  background: 3fff;
  color: #c7352e;
}
.tabs ul.tab-list li a[aria-selected] {
  padding: 0.75rem 1.1875rem;
  border-color: #333;
  background: #fff;
  color: #333;
}
.tabs ul.tab-list li a[aria-selected]:hover, .tabs ul.tab-list li a[aria-selected][role=tab]:focus {
  border-color: #c7352e;
  background: #fff;
  color: #c7352e;
}
.tabs [role=tabpanel] {
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
}
.tabs [role=tabpanel][aria-hidden=true] {
  display: none;
}
.tabs [role=tabpanel] .tab-title {
  display: none;
}

table.tablesaw {
  margin: 0 0 1.5rem 0;
  padding: 0;
  max-width: 100%;
  width: 100%;
  border: 0;
  border-collapse: collapse;
  empty-cells: show;
}

.tablesaw * {
  box-sizing: border-box;
}

.tablesaw-stack td .tablesaw-cell-label,
.tablesaw-stack th .tablesaw-cell-label {
  display: none;
}

@media only screen {
  .tablesaw-stack {
    clear: both;
  }
  .tablesaw-stack td,
.tablesaw-stack th {
    display: block;
    text-align: left;
  }
  .tablesaw-stack tr {
    display: table-row;
    clear: both;
  }
  .tablesaw-stack td .tablesaw-cell-label,
.tablesaw-stack th .tablesaw-cell-label {
    display: inline-block;
    padding: 0 10px 0 0;
    width: 30%;
  }
  .tablesaw-stack th .tablesaw-cell-label-top,
.tablesaw-stack td .tablesaw-cell-label-top {
    display: block;
    margin: 6px 0;
    padding: 6px 0;
  }
  .tablesaw-stack .tablesaw-cell-label {
    display: block;
  }
  .tablesaw-stack tbody th.group {
    margin-top: -1px;
  }
  .tablesaw-stack th.group b.tablesaw-cell-label {
    display: none !important;
  }
}
@media (max-width: 30rem) {
  table.tablesaw-stack {
    margin: 0 -1.25rem 1.5rem -1.25rem;
    max-width: 100%;
    width: calc(100% + 40px);
  }
  table.tablesaw-stack tbody tr {
    display: block;
    margin: 0 1.25rem;
    padding-top: 1.25rem;
    width: 100%;
  }
  table.tablesaw-stack tbody tr:first-child {
    border-top: 5px solid #333333;
  }
  table.tablesaw-stack tbody tr:last-child {
    border-bottom: 1px solid gray;
  }
  table.tablesaw-stack thead {
    display: none;
  }
  table.tablesaw-stack tbody td,
table.tablesaw-stack tbody th {
    display: block;
    clear: left;
    padding: 0;
    width: 100%;
    border-bottom-width: 0;
  }
  table.tablesaw-stack tbody td:last-child,
table.tablesaw-stack tbody th:last-child {
    border-bottom-width: 1px;
  }
  table.tablesaw-stack td .tablesaw-cell-label {
    display: block;
    width: 100%;
    background: transparent;
    vertical-align: top;
    text-transform: uppercase;
    font-weight: 700;
  }
  table.tablesaw-stack td .tablesaw-cell-content {
    display: inline-block;
    padding: 0 0 1.25rem 0;
    width: 100%;
  }
  table.tablesaw-stack .tablesaw-stack-block .tablesaw-cell-label,
table.tablesaw-stack .tablesaw-stack-block .tablesaw-cell-content {
    display: block;
    padding: 0;
    max-width: 100%;
    width: 100%;
  }
  table.tablesaw-stack td:empty,
table.tablesaw-stack th:empty {
    display: none;
  }
}
@media (min-width: 40em) {
  .tablesaw-stack tr {
    display: table-row;
  }
  .tablesaw-stack td,
.tablesaw-stack th,
.tablesaw-stack thead td,
.tablesaw-stack thead th {
    display: table-cell;
    margin: 0;
  }
  .tablesaw-stack td .tablesaw-cell-label,
.tablesaw-stack th .tablesaw-cell-label {
    display: none !important;
  }
  .tablesaw-stack thead th {
    padding: 0.625rem 1.125rem 0.5rem;
    color: black;
    text-transform: uppercase;
    font-weight: 700;
  }
  .tablesaw-stack thead th:last-child {
    border-right: 0;
  }
  .tablesaw-stack tbody tr td {
    padding: 0.75rem 1.125rem 0.625rem;
  }
  .tablesaw-stack tbody tr td:last-child {
    border-right: 0;
  }
}
.flex-container {
  max-width: 100%;
  width: 100%;
}
@media (min-width: 1024px) {
  .flex-container.alignleft {
    float: left;
    margin: 0 3.4375rem 0.625rem 0;
    width: 40%;
  }
  .flex-container.alignright {
    float: right;
    margin: 0 0 0.625rem 3.4375rem;
    width: 40%;
  }
  .flex-container.aligncenter {
    margin: 0 auto 0.625rem auto;
    width: 40%;
  }
}

.flex-video {
  position: relative;
  padding-top: 1.5625rem;
  padding-bottom: 56.25%;
  height: 0;
}
.flex-video iframe,
.flex-video object,
.flex-video embed,
.flex-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.accordions h2.accordion-title button,
.accordions h3.accordion-title button {
  padding-right: 40px;
  font-weight: 300;
}

.accordions.acc-large {
  margin-top: 50px;
  margin-left: -20px;
  width: calc(100vw + 5px);
}
@media screen and (min-width: 1024px) {
  .accordions.acc-large {
    margin-left: -50px;
  }
}
.accordions.acc-large h2.accordion-title button,
.accordions.acc-large h3.accordion-title button {
  letter-spacing: 0.05em;
  font-size: 1.5rem;
  line-height: 1.5rem;
  padding: 30px 100px 30px 20px;
  border-bottom: 0;
  background: #e5e5e5;
  font-weight: 300;
}
@media screen and (min-width: 1024px) {
  .accordions.acc-large h2.accordion-title button,
.accordions.acc-large h3.accordion-title button {
    letter-spacing: 0.05em;
    font-size: 1.5625rem;
    line-height: 1.5625rem;
    padding: 35px 50px;
  }
}
.accordions.acc-large h2.accordion-title button:hover, .accordions.acc-large h2.accordion-title button:focus,
.accordions.acc-large h3.accordion-title button:hover,
.accordions.acc-large h3.accordion-title button:focus {
  border-bottom: 0;
  background: #c7352e;
  color: #fff;
  font-weight: 400;
}
.accordions.acc-large h2.accordion-title button:hover span:after, .accordions.acc-large h2.accordion-title button:focus span:after,
.accordions.acc-large h3.accordion-title button:hover span:after,
.accordions.acc-large h3.accordion-title button:focus span:after {
  background-image: url("../images/plus_large-white.png");
}
.accordions.acc-large h2.accordion-title button span:after,
.accordions.acc-large h3.accordion-title button span:after {
  right: 50px;
  width: 30px;
  height: 30px;
  background-image: url("../images/plus_large.png");
}
.accordions.acc-large h2.accordion-title.is-active button span:after,
.accordions.acc-large h3.accordion-title.is-active button span:after {
  background-image: url("../images/minus_large.png");
}
.accordions.acc-large h2.accordion-title.is-active button:hover span:after, .accordions.acc-large h2.accordion-title.is-active button:focus span:after,
.accordions.acc-large h3.accordion-title.is-active button:hover span:after,
.accordions.acc-large h3.accordion-title.is-active button:focus span:after {
  background-image: url("../images/minus_large-white.png");
}
.accordions.acc-large .accordion-content {
  padding: 15px 20px 60px;
  color: #333;
}
@media screen and (min-width: 1024px) {
  .accordions.acc-large .accordion-content {
    padding: 50px 50px 100px;
  }
}
.accordions.acc-large .accordion-content p {
  letter-spacing: 0.001em;
  font-size: 1rem;
  line-height: 1.5rem;
  padding-top: 25px;
}
.accordions.acc-large .accordion-content ul {
  letter-spacing: 0.001em;
  font-size: 1rem;
  line-height: 1.5rem;
}
.accordions.acc-large .accordion-content ul li {
  margin-bottom: 0;
  padding: 20px 0;
  border-bottom: 1px solid #cbcbcb;
}
.accordions.acc-large .accordion-content ul li:before {
  content: none;
}

.gallery-container {
  position: relative;
  overflow: hidden;
  margin-top: -50px;
  margin-right: 0;
  margin-left: -20px;
  width: calc(100% + 40px);
  height: auto;
}
@media screen and (min-width: 640px) {
  .gallery-container {
    margin-top: -45px;
    margin-left: -20px;
    height: 450px;
  }
}
@media screen and (min-width: 1024px) {
  .gallery-container {
    margin-top: -45px;
    margin-left: -50px;
    width: calc(100% + 100px);
    height: 450px;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .gallery-container {
    /* IE10+ Specific CSS styles */
    margin-top: 0;
  }
  .gallery-container img {
    max-width: inherit !important;
    width: inherit !important;
  }
}

.gallery-nav {
  position: absolute;
  top: 50%;
  z-index: 25;
  display: none;
  width: 50px;
  height: 100px;
  border: 1px solid #fff;
  background: #000;
  color: #fff;
  text-align: center;
  font-size: 3rem;
  opacity: 0.6;
  cursor: pointer;
  transform: translateY(-50%);
  align-items: center;
  justify-content: center;
}
.gallery-nav.active {
  display: none;
}
@media screen and (min-width: 640px) {
  .gallery-nav.active {
    display: flex;
  }
}
.gallery-nav:hover, .gallery-nav:focus {
  opacity: 1;
}
.gallery-nav.previous {
  left: 20px;
  border-left: 0;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .gallery-nav.previous {
    left: 15px;
  }
}
.gallery-nav.next {
  right: 20px;
  border-right: 0;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .gallery-nav.next {
    right: 15px;
  }
}
.gallery-nav.restart {
  right: 20px;
  border-right: 0;
  font-size: 2rem;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .gallery-nav.restart {
    right: 15px;
  }
}
.gallery-nav.count {
  letter-spacing: 0.001em;
  font-size: 0.75rem;
  line-height: 1.5rem;
  position: absolute;
  top: 20px;
  right: 30px;
  display: block;
  padding: 0;
  height: auto;
  border: 0;
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
}
@media screen and (min-width: 640px) {
  .gallery-nav.count {
    display: none;
  }
}

.photo-gallery {
  position: relative;
  left: 0;
  display: inline-flex;
  height: 100%;
  transition: left 0.5s ease-in-out;
  align-items: center;
}
@media screen and (min-width: 640px) {
  .photo-gallery {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
}
.photo-gallery img,
.photo-gallery .image-preview {
  position: relative;
  width: 100vw;
}
.photo-gallery img:first-child,
.photo-gallery .image-preview:first-child {
  display: block;
}
@media screen and (min-width: 640px) {
  .photo-gallery img,
.photo-gallery .image-preview {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    width: 291px;
    height: calc(50% - 5px);
  }
  .photo-gallery img:first-child,
.photo-gallery .image-preview:first-child {
    width: auto;
    height: calc(100% - 5px);
  }
}

.image-preview a[data-mfp-src*="sddefault.jpg"] {
  position: relative;
}
.image-preview a[data-mfp-src*="sddefault.jpg"]:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: block;
  width: 286px;
  height: 215px;
  background: url("/themes/isringhausen/assets/dist/images/ICO-play.png") no-repeat;
  background-size: cover;
}

a.popup-youtube:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: block;
  width: 100%;
  height: 100%;
  background: url("/themes/isringhausen/assets/dist/images/ICO-play.png") no-repeat;
  background-size: cover;
  content: "";
}
@media screen and (min-width: 640px) {
  a.popup-youtube:before {
    width: 286px;
    height: 215px;
  }
}

body.show-filters {
  overflow: hidden;
}

.subpage.inventory main {
  margin-top: 50px;
}
.subpage .main-content .inventory {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (min-width: 1024px) {
  .subpage .main-content .inventory {
    flex-wrap: nowrap;
  }
}
.subpage .main-content .inventory-search {
  padding-top: 25px;
  width: 100%;
  border-top: 1px solid #cccccc;
}
@media screen and (min-width: 1024px) {
  .subpage .main-content .inventory-search {
    margin-right: 50px;
    min-width: 300px;
    width: 25%;
  }
}
.subpage .main-content .inventory-search a {
  position: relative;
  padding-left: 25px;
  color: #333333;
  text-decoration: none;
  letter-spacing: 0.001em;
  font-size: 1rem;
  line-height: 1.5625rem;
  font-family: "Roboto", sans-serif;
}
.subpage .main-content .inventory-search a:before {
  position: absolute;
  top: 50%;
  left: 0;
  box-sizing: border-box;
  width: 15px;
  height: 15px;
  border: 1px solid #a7a7a7;
  content: "";
  transform: translateY(-50%);
}
.subpage .main-content .inventory-search a.active-filter:before {
  background-image: url(../images/red-x.png);
  background-position: center;
  background-size: 9px;
  background-repeat: no-repeat;
}
.subpage .main-content .inventory-search a.clear-filters-btn {
  z-index: 10;
  display: block;
  float: right;
  color: #c7352e;
  font-weight: 500;
  cursor: pointer;
}
.subpage .main-content .inventory-search a.clear-filters-btn:before {
  content: none;
}
.subpage .main-content .inventory-search input {
  background: #f2f2f2;
}
.subpage .main-content .inventory-search input::placeholder {
  color: #333333;
  font-weight: 400;
  font-style: italic;
  font-family: "Roboto", sans-serif;
}
.subpage .main-content .inventory-search ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.subpage .main-content .inventory-search ul li {
  margin: 0 0 5px 0;
  padding: 0;
  border-bottom: 0;
}
.subpage .main-content .inventory-search ul li:before {
  content: none;
}
.subpage .main-content .inventory-search .search-control {
  position: relative;
}
.subpage .main-content .inventory-search .search-control input {
  width: 100%;
}
.subpage .main-content .inventory-search .search-control a:before {
  content: none;
}
.subpage .main-content .inventory-search .search-btn {
  position: absolute;
  top: 50%;
  right: 10px;
  border: 0;
  background: 0;
  color: #333333;
  transform: translateY(-50%);
}
.subpage .main-content .inventory-search .results-heading {
  margin: 0 0 25px 0;
  text-transform: capitalize;
  font-weight: bold;
  letter-spacing: 0.001em;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  font-family: "Roboto", sans-serif;
}
.subpage .main-content .inventory-search .applied-filters {
  margin-bottom: 40px;
}
.subpage .main-content .inventory-search .applied-filters ul {
  margin-bottom: 25px;
}
.subpage .main-content .inventory-search .applied-filters a {
  color: #c7352e;
}
.subpage .main-content .inventory-search .applied-filters a:before {
  border: 0;
  background-image: url(../images/red-x.png);
  background-position: center;
  background-size: 9px;
  background-repeat: no-repeat;
}
.subpage .main-content .inventory-control {
  position: relative;
  padding-top: 50px;
  width: 100%;
  border-top: 1px solid #cccccc;
}
.subpage .main-content .inventory-control .inventory-sort-dropdown {
  position: absolute;
  top: -91px;
  right: 0;
  width: 50%;
  letter-spacing: 0.001em;
  font-size: 1.125rem;
  line-height: 1.125rem;
}
@media screen and (min-width: 1024px) {
  .subpage .main-content .inventory-control .inventory-sort-dropdown {
    top: -80px;
    min-width: 200px;
    width: auto;
  }
}
.subpage .main-content .inventory-control .inventory-sort-dropdown select {
  padding: 14px 13px;
  width: 100%;
  border: 1px solid #cfcfcf;
  background: transparent url("../images/select_dd_arrow.png") no-repeat calc(100% - 15px) center;
  background-size: 15px;
  font-family: "Roboto", sans-serif;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.subpage .main-content .inventory-control .inventory-list .vehicle {
  position: relative;
  display: flex;
  padding: 50px 0;
  border: 1px solid #cfcfcf;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  align-items: center;
  flex-wrap: wrap;
}
@media screen and (min-width: 1024px) {
  .subpage .main-content .inventory-control .inventory-list .vehicle {
    flex-wrap: nowrap;
  }
}
.subpage .main-content .inventory-control .inventory-list .vehicle:first-child {
  margin-top: -50px;
}
.subpage .main-content .inventory-control .inventory-list .vehicle .image {
  display: inline-block;
  margin-right: 0;
  width: 100%;
  height: auto;
}
@media screen and (min-width: 1024px) {
  .subpage .main-content .inventory-control .inventory-list .vehicle .image {
    margin-right: 50px;
    width: 33%;
  }
}
.subpage .main-content .inventory-control .inventory-list .vehicle .image img {
  width: 100%;
}
.subpage .main-content .inventory-control .inventory-list .vehicle .details {
  position: relative;
  display: inline-block;
  margin-top: 25px;
  width: 100%;
  letter-spacing: 0.001em;
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-family: "Roboto", sans-serif;
}
@media screen and (min-width: 1024px) {
  .subpage .main-content .inventory-control .inventory-list .vehicle .details {
    margin-top: 0;
    width: calc(67% - 55px);
  }
}
.subpage .main-content .inventory-control .inventory-list .vehicle .info {
  display: inline-block;
  margin-bottom: 20px;
}
@media only screen and (max-width: 1280px) {
  .subpage .main-content .inventory-control .inventory-list .vehicle .info {
    position: relative;
  }
}
.subpage .main-content .inventory-control .inventory-list .vehicle .info img {
  margin: 10px;
  vertical-align: middle;
}
@media only screen and (max-width: 1280px) {
  .subpage .main-content .inventory-control .inventory-list .vehicle .info img {
    margin-left: 0;
  }
}
.subpage .main-content .inventory-control .inventory-list .vehicle h3 {
  margin-top: 0;
  color: #000000;
  text-transform: capitalize;
  font-weight: bold;
  letter-spacing: 0.01em;
  font-size: 1.5625rem;
  line-height: 1.875rem;
  font-family: "Roboto", sans-serif;
}
.subpage .main-content .inventory-control .inventory-list .vehicle .price {
  margin: 20px 0;
  font-weight: bold;
  letter-spacing: 0.001em;
  font-size: 1.5rem;
  line-height: 1.5rem;
}
.subpage .main-content .inventory-control .inventory-list .vehicle .vehicle-actions {
  max-width: 800px;
  display: flex;
  flex-wrap: wrap;
}
.subpage .main-content .inventory-control .inventory-list .vehicle .vehicle-actions a {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 15px 15px 0;
  max-width: 300px;
  color: #ffffff;
  text-decoration: none;
  text-transform: none;
  font-size: 16px;
}
.subpage .main-content .inventory-control .inventory-list .vehicle .vehicle-actions a span {
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 6px;
}
.subpage .main-content .advanced-search-container {
  position: fixed;
  top: 0;
  left: -100vw;
  z-index: 10;
  overflow-y: auto;
  padding: 0 20px 60px 20px;
  width: 100%;
  height: 100vh;
  background: #ffffff;
  transition: left 0.5s ease-in-out;
}
@media (min-width: 1024px) {
  .subpage .main-content .advanced-search-container {
    position: static;
    padding: 0;
    width: auto;
    height: auto;
    background: transparent;
  }
}
.subpage .main-content .advanced-search-container.active {
  left: 0;
}
.subpage .main-content .advanced-search-container .year-search {
  width: 100%;
}
.subpage .main-content .advanced-search-container .year-search input.year {
  display: inline-block;
  margin: 0 0.625rem;
  min-width: 50px;
  width: 25%;
  font-size: 1rem;
}
.subpage .main-content .advanced-search-container .year-search input.year:first-child {
  margin-left: 0;
}
.subpage .main-content .advanced-search-container .year-search input[type=submit] {
  display: inline;
  padding: 0.9375rem 0.9375rem;
  min-width: 50px;
  width: 29%;
  border: 0.0625rem solid #003863;
  background-color: #003863;
}
.subpage .main-content .search-actions {
  display: flex;
  overflow: hidden;
  margin-bottom: 25px;
  margin-left: -20px;
  padding: 0 20px;
  width: calc(100% + 40px);
  height: 50px;
  background: #333333;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 1024px) {
  .subpage .main-content .search-actions {
    display: none;
  }
}
.subpage .main-content .search-actions h3 {
  margin: 0;
  color: #ffffff;
  font-weight: bold;
  letter-spacing: 0.05em;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  font-family: "Roboto", sans-serif;
}
.subpage .main-content .search-actions .close-filters {
  border: 0;
  background: transparent;
  color: #ffb500;
  letter-spacing: 0.05em;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  font-family: "Roboto", sans-serif;
}
.subpage .main-content .show-filters {
  padding: 15px;
  width: 50%;
  border: 1px solid #cccccc;
  background: transparent;
  text-align: left;
  letter-spacing: 0.001em;
  font-size: 1.125rem;
  line-height: 1.125rem;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
}
@media (min-width: 1024px) {
  .subpage .main-content .show-filters {
    display: none;
  }
}
.subpage .main-content .show-filters .fa {
  float: right;
}

.subpage .main-content .spin-details-container {
  max-width: 640px;
}
.subpage .main-content .vehicle-details {
  position: relative;
}
.subpage .main-content .vehicle-details .page-actions {
  top: 0;
  right: 0;
  margin: 10px 0 0 0;
  color: #737373;
  text-align: left;
  letter-spacing: 0.05em;
  font-size: 1.5rem;
  line-height: 2.625rem;
}
@media screen and (min-width: 1024px) {
  .subpage .main-content .vehicle-details .page-actions {
    position: absolute;
    margin: 0;
  }
}
.subpage .main-content .vehicle-details .page-actions a {
  color: #737373;
  text-decoration: none;
}
.subpage .main-content .vehicle-details .page-actions .fa {
  margin-right: 15px;
  cursor: pointer;
}
.subpage .main-content .vehicle-details .page-actions .fa:hover {
  color: #333333;
}
.subpage .main-content .vehicle-details h3.vehicle-title {
  margin: 40px 0 10px;
  color: #000000;
  text-transform: capitalize;
  font-weight: bold;
  letter-spacing: 0.01em;
  font-size: 1.5625rem;
  line-height: 1.875rem;
}
@media screen and (min-width: 1024px) {
  .subpage .main-content .vehicle-details h3.vehicle-title {
    margin-top: 50px;
    padding-right: 20%;
  }
}
.subpage .main-content .vehicle-details h4.vehicle-price {
  margin: 10px 0 25px;
  color: #000000;
  font-weight: bold;
  letter-spacing: 0.001em;
  font-size: 1.5rem;
  line-height: 1.875rem;
}
@media screen and (min-width: 1024px) {
  .subpage .main-content .vehicle-details h4.vehicle-price {
    min-height: 20px;
  }
}
.subpage .main-content .vehicle-details .vehicle-specs {
  font-size: 1rem;
}
.subpage .main-content .vehicle-details .vehicle-specs .vehicle-actions {
  max-width: 350px;
}
.subpage .main-content .vehicle-details .vehicle-specs .vehicle-actions a {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 15px 15px 0;
  color: #ffffff;
  text-decoration: none;
  text-transform: none;
  font-size: 16px;
}
.subpage .main-content .vehicle-details .vehicle-specs .vehicle-actions a span {
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 6px;
}
@media screen and (min-width: 1024px) {
  .subpage .main-content .vehicle-details .vehicle-specs {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}
.subpage .main-content .vehicle-details .vehicle-specs .more-info {
  position: relative;
  margin-bottom: 40px;
  padding: 40px 0;
  width: 100%;
  border-bottom: 1px solid #c6c6c6;
}
@media screen and (min-width: 1024px) {
  .subpage .main-content .vehicle-details .vehicle-specs .more-info {
    display: inline-flex;
    margin: 0;
    padding: 0;
    width: auto;
    border: 0;
  }
}
.subpage .main-content .vehicle-details .vehicle-specs .more-info a,
.subpage .main-content .vehicle-details .vehicle-specs .more-info span {
  display: inline-block;
}
@media screen and (min-width: 1024px) {
  .subpage .main-content .vehicle-details .vehicle-specs .more-info a,
.subpage .main-content .vehicle-details .vehicle-specs .more-info span {
    position: relative;
    margin: 0 15px;
  }
}
.subpage .main-content .vehicle-details .vehicle-specs .more-info a:last-child,
.subpage .main-content .vehicle-details .vehicle-specs .more-info span:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 1024px) {
  .subpage .main-content .vehicle-details .vehicle-specs .more-info a:last-child,
.subpage .main-content .vehicle-details .vehicle-specs .more-info span:last-child {
    margin-bottom: 25px;
  }
}
.subpage .main-content .vehicle-details .vehicle-specs .more-info a img,
.subpage .main-content .vehicle-details .vehicle-specs .more-info span img {
  vertical-align: middle;
}
.subpage .main-content .vehicle-details .vehicle-specs > ul,
.subpage .main-content .vehicle-details .vehicle-specs > div {
  margin-right: 50px;
}
.subpage .main-content .vehicle-details .vehicle-specs > ul:last-child,
.subpage .main-content .vehicle-details .vehicle-specs > div:last-child {
  margin-right: 0;
  flex-grow: 1;
}
.subpage .main-content .vehicle-details .vehicle-specs ul {
  margin: 0 50px 30px 0;
  padding: 0;
  letter-spacing: 0.001em;
  font-size: 1.125rem;
  line-height: 1.5rem;
}
.subpage .main-content .vehicle-details .vehicle-specs ul li {
  margin: 0;
  padding: 0;
  border: 0;
}
.subpage .main-content .vehicle-details .vehicle-specs ul li:before {
  content: none;
}
.subpage .main-content .vehicle-details .vehicle-specs .btn {
  margin-bottom: 10px;
}
@media print {
  .subpage .main-content header,
.subpage .main-content .page-actions,
.subpage .main-content .vehicle-actions,
.subpage .main-content .service-appointment,
.subpage .main-content .testimonials.parallax-window,
.subpage .main-content .standard-equipment,
.subpage .main-content .more-info,
.subpage .main-content footer {
    display: none;
  }
}
@media print and (-ms-high-contrast: none) {
  .subpage .main-content .image-preview img {
    width: 100% !important;
  }
}
@media print {
  .subpage .main-content .image-preview img {
    width: 100%;
  }
}
@media print {
  .subpage .main-content .image-preview ~ .image-preview {
    display: none;
  }
}
@media print {
  .subpage .main-content .vehicle-details {
    padding-bottom: 20px;
  }
  .subpage .main-content .vehicle-details h4.vehicle-price {
    margin-bottom: 10px !important;
  }
}
@media print {
  .subpage .main-content .testimonials {
    background: none !important;
  }
}
@media print {
  .subpage .main-content .accordions.acc-large {
    margin: 0 !important;
    border: none !important;
  }
}
@media print {
  .subpage .main-content h3.accordion-title button {
    padding: 0 3.125rem !important;
  }
}
@media print {
  .subpage .main-content .accordion-content[aria-hidden] {
    display: block !important;
  }
}
@media print {
  .subpage .main-content .vehicle-standard-options.accordion-content {
    display: none !important;
  }
}
@media print {
  .subpage .main-content .accordion-content {
    margin-bottom: 30px;
    padding: 0 3.125rem !important;
  }
  .subpage .main-content .accordion-content p,
.subpage .main-content .accordion-content ul,
.subpage .main-content .accordion-content ol,
.subpage .main-content .accordion-content li {
    padding: 0 !important;
    border: none !important;
    list-style: initial !important;
    font-size: 12px !important;
    line-height: 1.5 !important;
  }
}

.team {
  display: inline-flex;
  max-width: 1200px;
  width: 100%;
  flex-wrap: wrap;
}
.team .team-member {
  margin: 0 20px 20px 0;
}
.team .team-member .name {
  font-weight: 700;
}
.team .team-member .name a {
  padding-right: 10px;
  text-decoration: none;
}
.team .team-member .name a:hover {
  text-decoration: underline;
}
.team .team-member .name:after {
  color: black;
  content: "\f0e0";
  font-weight: 100;
  font-family: FontAwesome;
}
.team .team-member .title {
  font-style: italic;
  font-size: 14px;
}