.accordions {
    h2.accordion-title button,
    h3.accordion-title button {
        padding-right: 40px;
        font-weight: 300;
    }
}

.accordions.acc-large {
    margin-top: 50px;
    margin-left: -20px;
    width: calc(100vw + 5px);

    @media screen and (min-width: $large) {
        margin-left: -50px;
    }

    h2.accordion-title,
    h3.accordion-title {
        button {
            @include fontsize(24 24 50);

            padding: 30px 100px 30px 20px;
            border-bottom: 0;
            background: #e5e5e5;
            font-weight: 300;

            @media screen and (min-width: $large) {
                @include fontsize(25 25 50);

                padding: 35px 50px;
            }

            &:hover,
            &:focus {
                border-bottom: 0;
                background: #c7352e;
                color: #fff;
                font-weight: 400;

                span:after {
                    background-image: url('../images/plus_large-white.png');
                }
            }

            span:after {
                right: 50px;
                width: 30px;
                height: 30px;
                background-image: url('../images/plus_large.png');
            }
        }

        &.is-active {
            button {
                span:after {
                    background-image: url('../images/minus_large.png');
                }

                &:hover,
                &:focus {
                    span:after {
                        background-image: url('../images/minus_large-white.png');
                    }
                }
            }
        }
    }

    .accordion-content {
        padding: 15px 20px 60px;
        color: #333;

        @media screen and (min-width: $large) {
            padding: 50px 50px 100px;
        }

        p {
            @include fontsize(16 24 1);

            padding-top: 25px;
        }

        ul {
            @include fontsize(16 24 1);

            li {
                margin-bottom: 0;
                padding: 20px 0;
                border-bottom: 1px solid #cbcbcb;

                &:before {
                    content: none;
                }
            }
        }
    }
}
