.accordions {
  margin-bottom: rem-calc(20px);

  h2.accordion-title,
  h3.accordion-title {
    position: relative;
    margin: 0;

    button {
      position: relative;
      margin: 0;
      padding: rem-calc(15px 0 10px 0);
      width: 100%;
      border: 0;
      border-bottom: 1px solid #d3d3d3;
      background: transparent;
      color: #333333;
      text-align: left;
      text-transform: uppercase;
      font-weight: 700;
      @include fontsize(18 30 50);

      span {
        position: absolute;
        right: 0;
        bottom: 0;
        width: rem-calc(80px);
        height: 100%;
        border: 0;

        &:after {
          position: absolute;
          top: 50%;
          right: 0;
          width: 15px;
          height: 15px;
          background: transparent url(../images/plus.png) no-repeat center center;
          background-size: contain;
          content: '';
          text-align: center;
          transform: translateY(-50%);
        }
      }

      &:hover {
        cursor: pointer;
      }

      &:hover,
      &:focus {
        outline: 0;
        border: 0;
        border-bottom: 1px solid #c7352e;
        background: transparent;
        color: #c7352e;

        span {
          border: 0;

          &:after {
            color: #c7352e;
          }
        }
      }
    }

    &.is-active button,
    &.is-active button:focus {
      outline: 0;
      border: 0;
      border-bottom: 0;
      background: transparent;
      color: #333333;

      span {
        border: 0;

        &:after {
          background-image: url(../images/minus.png);
        }
      }

      a.clear-filters-btn {
        display: block;
      }
    }

    a.clear-filters-btn {
      position: absolute;
      right: 20px;
      display: none;
      margin: 0;
      padding: 0;
      color: #c7352e;
      text-transform: uppercase;
      font-weight: medium;
      @include fontsize(13 25 50);
      font-family: 'Roboto', sans-serif;

      &:before {
        content: none;
      }
    }
  }

  .accordion-content[aria-hidden] {
    display: none;
  }

  .accordion-content[aria-hidden='false'] {
    display: block;
  }

  &.active {
    h2 {
      margin: 0;
    }

    .accordion-content {
      padding: 0 0 15px 0;
      border-bottom: 1px solid #d3d3d3;
      color: #333333;
      @include fontsize(18 30 1);

      p:first-child {
        margin-top: 0;
      }
    }
  }
}