.tabs {
    position: relative;
    margin-bottom: rem-calc(20px);

    &:not(.location-tabs).scroll-left:before,
    &:not(.location-tabs).scroll-right:after {
        position: absolute;
        z-index: 99;
        width: rem-calc(40px);
        height: rem-calc(60px);
        content: '';
    }

    &:not(.location-tabs).scroll-left:before {
        top: 0;
        left: 0;
        background: linear-gradient(to right, rgba(white, 1) 0%, rgba(white, 0) 100%);
    }

    &:not(.location-tabs).scroll-right:after {
        top: 0;
        right: 0;
        background: linear-gradient(to left, rgba(white, 1) 0%, rgba(white, 0) 100%);
    }

    .tab-con {
        position: relative;
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        margin-bottom: 2px;
        padding: 0;
        white-space: nowrap;
    }

    ul.tab-list {
        @include fontsize(18 24 1);

        display: none;
        margin: 0;
        padding: 0;
        list-style: none;
        text-transform: capitalize;
        letter-spacing: 1px;
        font-weight: 900;
        font-family: 'Roboto', sans-serif;

        &[role='tablist'] {
            display: inline-block;
        }

        li {
            display: inline-block;
            margin: 0 rem-calc(10px) 0 0;
            padding: 0;
            border-bottom: 0;
            white-space: nowrap;

            &:last-child {
                margin: 0;
            }

            a {
                position: relative;
                display: block;
                padding: rem-calc(12px 19px);
                border-top: 5px solid #ccc;
                background: #fff;
                color: #333;
                text-align: center;
                text-decoration: none;

                &:hover,
                &[role='tab']:focus {
                    outline: 0;
                    border-color: #c7352e;
                    background: 3fff;
                    color: #c7352e;
                }

                &[aria-selected] {
                    padding: rem-calc(12px 19px);
                    border-color: #333;
                    background: #fff;
                    color: #333;

                    &:hover,
                    &[role='tab']:focus {
                        border-color: #c7352e;
                        background: #fff;
                        color: #c7352e;
                    }
                }
            }
        }
    }

    [role='tabpanel'] {
        border-top: 1px solid #333;
        border-bottom: 1px solid #333;

        &[aria-hidden='true'] {
            display: none;
        }

        .tab-title {
            display: none;
        }
    }
}