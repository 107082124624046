.flex-container {
  max-width: 100%;
  width: 100%;

@include large-up {
    &.alignleft {
      float: left;
      margin: 0 rem-calc(55px 10px) 0;
      width: 40%;
    }

    &.alignright {
      float: right;
      margin: 0 0 rem-calc(10px 55px);
      width: 40%;
    }

    &.aligncenter {
      margin: 0 auto rem-calc(10px) auto;
      width: 40%;
    }
  }
}

.flex-video {
  position: relative;
  padding-top: rem-calc(25px);
  padding-bottom: 56.25%;
  height: 0;

  iframe,
  object,
  embed,
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
