body.show-filters {
  overflow: hidden;
}

.subpage.inventory main {
  margin-top: 50px;

  @media screen and (min-width: $large) {
    // margin-top: 100px;
  }
}

.subpage .main-content {
  .inventory {
    display: flex;

    flex-wrap: wrap;

    @media screen and (min-width: $large) {
      flex-wrap: nowrap;
    }
  }

  .inventory-search {
    padding-top: 25px;
    width: 100%;
    border-top: 1px solid #cccccc;

    @media screen and (min-width: $large) {
      margin-right: 50px;
      min-width: 300px;
      width: 25%;
    }

    a {
      position: relative;
      padding-left: 25px;
      color: #333333;
      text-decoration: none;

      @include fontsize(16 25 1);
      font-family: 'Roboto', sans-serif;

      &:before {
        position: absolute;
        top: 50%;
        left: 0;
        box-sizing: border-box;
        width: 15px;
        height: 15px;
        border: 1px solid #a7a7a7;
        content: '';
        transform: translateY(-50%);
      }

      &.active-filter {
        &:before {
          background-image: url(../images/red-x.png);
          background-position: center;
          background-size: 9px;
          background-repeat: no-repeat;
        }
      }

      &.clear-filters-btn {
        z-index: 10;
        display: block;
        float: right;
        color: #c7352e;
        font-weight: 500;
        cursor: pointer;

        &:before {
          content: none;
        }
      }
    }

    input {
      background: #f2f2f2;

      &::placeholder {
        color: #333333;
        font-weight: 400;
        font-style: italic;
        font-family: 'Roboto', sans-serif;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        margin: 0 0 5px 0;
        padding: 0;
        border-bottom: 0;

        &:before {
          content: none;
        }
      }
    }

    .search-control {
      position: relative;

      input {
        width: 100%;
      }

      a:before {
        content: none;
      }
    }

    .search-btn {
      position: absolute;
      top: 50%;
      right: 10px;
      border: 0;
      background: 0;
      color: #333333;
      transform: translateY(-50%);
    }

    .results-heading {
      margin: 0 0 25px 0;
      text-transform: capitalize;
      font-weight: bold;

      @include fontsize(18 25 1);
      font-family: 'Roboto', sans-serif;
    }

    .applied-filters {
      margin-bottom: 40px;

      ul {
        margin-bottom: 25px;
      }

      a {
        color: #c7352e;

        &:before {
          border: 0;
          background-image: url(../images/red-x.png);
          background-position: center;
          background-size: 9px;
          background-repeat: no-repeat;
        }
      }
    }
  }

  .inventory-control {
    position: relative;
    padding-top: 50px;
    width: 100%;
    border-top: 1px solid #cccccc;

    .inventory-sort-dropdown {
      position: absolute;
      top: -91px;
      right: 0;
      width: 50%;

      @include fontsize(18 18 1);

      @media screen and (min-width: $large) {
        top: -80px;
        min-width: 200px;
        width: auto;
      }

      select {
        padding: 14px 13px;
        width: 100%;
        border: 1px solid #cfcfcf;
        background: transparent url('../images/select_dd_arrow.png') no-repeat calc(100% - 15px) center;
        background-size: 15px;
        font-family: 'Roboto', sans-serif;

        -webkit-appearance: none;
        -moz-appearance: none;
      }
    }

    .inventory-list {
      .vehicle {
        position: relative;
        display: flex;
        padding: 50px 0;
        border: 1px solid #cfcfcf;
        border-top: 0;
        border-right: 0;
        border-left: 0;

        align-items: center;
        flex-wrap: wrap;

        @media screen and (min-width: $large) {
          flex-wrap: nowrap;
        }

        &:first-child {
          margin-top: -50px;
        }

        .image {
          display: inline-block;
          margin-right: 0;
          width: 100%;
          height: auto;

          @media screen and (min-width: $large) {
            margin-right: 50px;
            width: 33%;
          }

          img {
            width: 100%;
          }
        }

        .details {
          position: relative;
          display: inline-block;
          margin-top: 25px;
          width: 100%;

          @include fontsize(18 24 1);
          font-family: 'Roboto', sans-serif;

          @media screen and (min-width: $large) {
            margin-top: 0;
            width: calc(67% - 55px);
          }
        }

        .info {
          // position: absolute;
          // bottom: 0;
          // left: 25%;
          display: inline-block;
          margin-bottom: 20px;

          @media only screen and (max-width: 1280px) {
            position: relative;
            // left: 0;
            // display: block;
          }

          img {
            margin: 10px;
            vertical-align: middle;

            @media only screen and (max-width: 1280px) {
              margin-left: 0;
            }
          }
        }

        h3 {
          margin-top: 0;
          color: #000000;
          text-transform: capitalize;
          font-weight: bold;
          @include fontsize(25 30 10);
          font-family: 'Roboto', sans-serif;
        }

        .price {
          margin: 20px 0;
          font-weight: bold;
          @include fontsize(24 24 1);
        }

        // a.view-details {
        //   vertical-align: top;
        // }

        .vehicle-actions {
          max-width: 800px;
          display: flex;
          flex-wrap: wrap;

          a {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 15px 15px 0;
            max-width: 300px;
            color: #ffffff;
            text-decoration: none;
            text-transform: none;
            font-size: 16px;

            span {
              display: inline-block;
              width: 25px;
              height: 25px;
              margin-right: 6px;
            }
          }
        }
      }
    }
  }

  .advanced-search-container {
    position: fixed;
    top: 0;
    left: -100vw;
    z-index: 10;
    overflow-y: auto;
    padding: 0 20px 60px 20px;
    width: 100%;
    height: 100vh;
    background: #ffffff;
    transition: left 0.5s ease-in-out;

    @media (min-width: $large) {
      position: static;
      padding: 0;
      width: auto;
      height: auto;
      background: transparent;
    }

    &.active {
      left: 0;
    }

    .year-search {
      // display: inline-flex !important;
      width: 100%;

      // span {
      //   position: relative;
      //   top: 10px;
      // }

      input.year {
        display: inline-block;
        margin: 0 0.625rem;
        min-width: 50px;
        width: 25%;
        font-size: 1rem;

        &:first-child {
          margin-left: 0;
        }
      }

      input[type='submit'] {
        display: inline;
        padding: 0.9375rem 0.9375rem;
        min-width: 50px;
        width: 29%;
        border: 0.0625rem solid #003863;
        background-color: #003863;
      }
    }
  }

  .search-actions {
    display: flex;
    overflow: hidden;
    margin-bottom: 25px;
    margin-left: -20px;
    padding: 0 20px;
    width: calc(100% + 40px);
    height: 50px;
    background: #333333;

    align-items: center;
    justify-content: space-between;

    @media (min-width: $large) {
      display: none;
    }

    h3 {
      margin: 0;
      color: #ffffff;
      font-weight: bold;

      @include fontsize(15 24 50);
      font-family: 'Roboto', sans-serif;
    }

    .close-filters {
      border: 0;
      background: transparent;
      color: #ffb500;

      @include fontsize(15 24 50);
      font-family: 'Roboto', sans-serif;
    }
  }

  .show-filters {
    padding: 15px;
    width: 50%;
    border: 1px solid #cccccc;
    background: transparent;
    text-align: left;

    @include fontsize(18 18 1);
    font-family: 'Roboto', sans-serif;
    cursor: pointer;

    @media (min-width: $large) {
      display: none;
    }

    .fa {
      float: right;
    }
  }
}